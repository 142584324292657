import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

const CustCheckbox = ({
  id,
  label,
  className,
  onChange,
  tabIndex,
  value,
  labelPosition,
  dataTip,
  disabled,
}) => {
  let checkboxStyle = {},
    labelStyle = { width: "inherit", "display": "inherit" };
  /*
    if (labelPosition === "left") {
    checkboxStyle = { position: "absolute", marginLeft: "10px" };
    labelStyle = { width: "inherit", "marginTop": "9px", "display": "inline-block" };
  }
  */

  return (
    <div className="comp-input-wrapper">
      <div className="comp-input-label" style={labelStyle}>
        <label htmlFor={id} data-tip={dataTip}>{label}</label>
      </div>
      <input
        id={id}
        className="ml-5 cust-checkbox"
        style={checkboxStyle}
        type="checkbox"
        checked={value}
        onClick={onChange}
        onChange={() => {}}
        tabIndex={tabIndex}
        disabled={disabled}
      />
      <ReactTooltip type="info" />
    </div>
  );
};

CustCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  value: PropTypes.any,
  dataTip: PropTypes.string,
  disabled: PropTypes.bool,
};

CustCheckbox.defaultProps = {
  tabIndex: 99,
  value: "",
  dataTip: undefined,
  disabled: undefined,
};

export default CustCheckbox;
