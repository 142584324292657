import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

function CustText({
  className,
  cssClassesWrapper,
  disabled,
  id,
  label,
  labelPosition,
  maxLength,
  onBlur,
  onChange,
  placeholder,
  required,
  setFocus,
  showValidationState,
  tabIndex,
  type,
  valid,
  value,
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (setFocus && ref?.current) ref.current.focus();
  }, [setFocus, ref]);

  const leftLabelAdj = labelPosition === "left" ? {"alignSelf": "center", "marginBottom": 0, "marginRight": "1rem"} : {};
  const leftWrapperAdj = labelPosition === "left" ? {"marginBottom": 0} : {};

  const handleFocus = (e) => {e.target.select()}

  return (
    <div
      className={`cust-text comp-input-wrapper${disabled ? " comp-disabled" : ""} ${
        !valid && showValidationState ? " comp-error" : ""
      } ${cssClassesWrapper}`}
      style={leftWrapperAdj}
    >
      <div className={labelPosition === "left" ? "flex" : "flex-col-left"}>
        <label className="comp-input-label" style={leftLabelAdj} htmlFor={id}>{label}{required ? " (required)" : ""}</label>        
          <input
            autoComplete="off"
            className={`comp-input ${className}`}
            disabled={disabled ? true : undefined}
            id={id}
            maxLength={maxLength}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={handleFocus}
            ref={ref}
            placeholder={placeholder}
            required={required}
            tabIndex={tabIndex}
            type={type}
            valid={valid ? "true" : "false"}
            value={value}
          />
      </div>
    </div>
  );
};

CustText.propTypes = {
  className: PropTypes.string,
  cssClassesWrapper: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelPosition: PropTypes.string,
  maxLength:PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showValidationState: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'integer', 'number']),
  valid: PropTypes.bool,
};

CustText.defaultProps = {
  className: "",
  cssClassesWrapper: "",
  disabled: false,
  labelPosition: "top",
  onBlur: () => {},
  onChange: () => {},
  placeholder: "",
  required: false,
  showValidationState: false,
  type: "text",
  valid: true,
};

export default CustText;
