import React, { Children } from "react";

export function CustRow(props) {  

  const childArray = Children.toArray(props.children);
  const columns = childArray.filter((x) => x.type === CustCol);
  
  const columnMarkup = [];
  columns.forEach((c, index) => {
    const colClass = c.props?.cols ? `p406-col-${c.props.cols}` : "";
    const offsetClass = c.props?.offset ? `p406-offset-${c.props.offset}` : "";
    if (c.props?.children) {
      columnMarkup.push (
        <div className={`p406-col ${c.props?.className ? c.props?.className: ""} ${colClass} ${offsetClass}`} key={index}>
          {c.props.children}
        </div>
      )
    }
  });

  return (
    <div className={`p406-row-flex ${props?.className ? props?.className: "p406-align-items-center"}`}>
      {columnMarkup}
    </div>
  );
  }

export function CustCol(props) {
  return null;
}

export default CustRow;

/*
example usage
<CustRow>
  <CustCol className="p406-col-2">
    <p>two columns using row</p>
  </CustCol>

  <CustCol className="p406-col-1 p406-offset-2">
    <p>one column with 2 offset</p>
  </CustCol>

  <CustCol className="p406-col-1">
    <p>third col</p>
  </CustCol>
</CustRow>
*/
