import axios from "axios";
import { API_SERVER, defaultAxiosConfig, WINDOW_OPEN_PARMS } from "../../constants";
import { convertBlobToBase64 } from "../../helpers";
import { toast } from "react-toastify";

export const fetchOrder = async (id) => {
  try {
    const response = await axios.get(`${API_SERVER}portal/guid/${id}`, {
      headers: defaultAxiosConfig().headers,
    });
    return response.data || {};

  } catch (error) {
    toast.error(error?.response?.data?.message || `Unable to retrieve order`);
    return {};
  }
};

export const submitPayment = async(guid, amount, selectedItems, paymentInfo) => {
  try {
    if (amount <= 0) {
      toast.error("Validation Error");
      return {data: {status: -1, message: ""}};
    };

    const response = await axios.post(`${API_SERVER}portal/guid/${guid}`, {
      amount,
      selectedItems,
      paymentInfo,
    }, {
      headers: defaultAxiosConfig().headers,
    });
    return response;
  } catch (error) {
    return {data: error.response.data};
  }
};

export const addReceipt = async(orderDispatch, receipt) => {
  try {
    const result = axios.post(
      `${API_SERVER}orders/receipts`,
      {
        ...receipt,
      },
      { headers: defaultAxiosConfig().headers }
    );

    toast.success(`Receipt created`);

    return result.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || `Unable to add receipt`);
    return false;
  }
};

export const fetchAchReceiptPdf = async (uuid, transactionIds) => {
  try {
    if (!transactionIds) return null;

    const response = await axios({
      method: "POST",
      responseType: "blob", //Force to receive data in a Blob Format
      url: `${API_SERVER}portal/generate-ach-receipt`,
      headers: defaultAxiosConfig().headers,
      data: {
        uuid,
        transactionIds,
      },
    });
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "Invoice", WINDOW_OPEN_PARMS);

    URL.revokeObjectURL(fileURL);
    
    return await convertBlobToBase64(file);
  } catch (err) {
    return null;
  };
};

export const fetchAchAuthPdf = async (uuid) => {
  try {
    const response = await axios({
      method: "POST",
      responseType: "blob", //Force to receive data in a Blob Format
      url: `${API_SERVER}portal/generate-ach-authorization`,
      headers: defaultAxiosConfig().headers,
      data: {
        uuid,
      },
    });
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "Authorization", WINDOW_OPEN_PARMS);

    URL.revokeObjectURL(fileURL);
    
    return await convertBlobToBase64(file);
  } catch (err) {
    return null;
  };
};

export const sendEmail = async (options) => {
  const styling = `<style>h3,p { margin: 0;}</style>`
  const {from, cc, to, subject, body, pdfName, pdfContent, type} = options;
  try {
    const results = await axios.post(
      `${API_SERVER}email`,
      {
        from,
        to,
        cc,
        subject,
        body: styling + body,
        attachment: {
          fileName: pdfName,
          content: pdfContent,
        },
        type: type,
        order: {...options.order},
      },
      { headers: defaultAxiosConfig().headers }
    );

    if (results.data.response.indexOf("250 OK") === -1) {
      toast.error(`Email error ${results.data.response}`);
      return false;
    }
    
    toast.success(`Email sent`);
    return true;
  
  } catch(error) {
    toast.error(`Email error ${error?.response?.data?.message}`);
    return false;
  }
}
