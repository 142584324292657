import { createContext } from "react";
import { TERMS } from "../constants";

const AppDataContext = createContext();

export const AppDataProvider = ({ children }) => {

  const initialSelectState = {
    selectState: {
      terms: TERMS.map((x) => x),
      // ensure that this is in sync with constant ORDER_STATUS
      orderStatus: [
        { id: "-20", text: "Cancelled", },
        { id: "-10", text: "On Hold", },
        { id: "010", text: "Needs Approval", },
        { id: "020", text: "Needs Mill Assignment", },
        { id: "030", text: "Ready for Mill Order", },
        { id: "040", text: "Mill Order Placed", },
        { id: "050", text: "Awaiting Prepay", },
        { id: "060", text: "Ready for Dispatch", },
        { id: "070", text: "Dispatched", },
        { id: "080", text: "Ready for Invoicing", },
        { id: "090", text: "Invoiced", },
        { id: "100", text: "Paid", },
      ],
    }
  };

   return (
    <AppDataContext.Provider
      value={{
        ...initialSelectState,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

export default AppDataContext;
