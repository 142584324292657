import React from "react";
import PropTypes from "prop-types";
import { DateBox } from 'devextreme-react/date-box';
import { isoDateToLocal } from "../../helpers";

const CustDate = ({
  label,
  id,
  onChange,
  tabIndex,
  value,
  minDate,
  maxDate,
  labelPosition,
  isClearable,
  className,
  disabled,
  showValidationState,
  suppressTopMargin,
  required,
  widgetStyleOverride
}) => {
  let widgetStyle = widgetStyleOverride || { marginTop: ".5rem" },
    containerStyle = {};

  if (labelPosition === "left") {
    widgetStyle = {
      position: "absolute",
      marginLeft: "1rem",
      marginTop: "-.9rem",
      display: "inline",
    };
    containerStyle = { marginLeft: "1rem", marginTop: suppressTopMargin ? "0" : "10px" };
  }

  const adjValue = isoDateToLocal(value);
  const adjMinDate = isoDateToLocal(minDate) || new Date(2020, 0, 1);
  const adjMaxDate = isoDateToLocal(maxDate);

   return (
    <div
      style={containerStyle}
      className={`cust-date ${className}${showValidationState && value === null ? " comp-error" : ""}`}
    >
      <label
        className="comp-input-label"
        style={{ display: "initial" }}
        htmlFor={id}
      >
        {label}{required ? " (required)" : ""}
      </label>

      <div style={widgetStyle}>
          <DateBox
            id={id}
            type="date"
            value={adjValue}
            displayFormat='MM/dd/yy'
            showLeadingZeros={true}
            min={adjMinDate}
            max={adjMaxDate}
            onValueChanged={onChange}
            tabIndex={tabIndex}
            showClearButton={isClearable && !disabled}
            clearIcon={isClearable && !disabled ? undefined : null}
            disabled={disabled ? true : undefined}
          />
      </div>
    </div>
  );
};

CustDate.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  value: PropTypes.any,
  labelPosition: PropTypes.string,
  isClearable: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  suppressTopMargin: PropTypes.bool,
};

CustDate.defaultProps = {
  value: null,
  minDate: null,
  maxDate: null,
  labelPosition: "",
  isClearable: true,
  className: "",
  disabled: false,
  suppressTopMargin: false,
};

export default CustDate;
