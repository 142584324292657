export const API_SERVER =
  window.location.hostname.indexOf("localhost") >= 0
    ? "http://localhost:8080/"
    : `https://api.${window.location.hostname}/`.replace("customer.", "saw.");

export const NAVIGATION = {
  home: "/",
  about: "/about",
  pay: "/pay",
};


// ensure that this is in sync with constant AppDataContext initialSelectState.selectState.orderStatus and server side constants
export const ORDER_STATUS = {
  quoteEmailSent: "-37",
  freightConfEmailSent: "-35",
  invoiceEmailSent: "-31",
  cancelled: "-20",
  onHold: "-10",
  needsApproval: "010",
  needsMillAssignment: "020",
  readyForMillOrder: "030",
  millOrderPlaced: "040",
  awaitingPrepay: "050",
  readyForDispatch: "060",
  dispatched: "070",
  readyForInvoicing: "080",
  invoiced: "090",
  paid: "100",
};

// sync terms with server constants
export const TERMS = [
  { id: "Net 10", daysDue: 10, upFrontPercent: 0, discountPercent: 0, discountDays: 0 },
  { id: "Net 30", daysDue: 30, upFrontPercent: 0, discountPercent: 0, discountDays: 0 },
  { id: "1% / 10, Net 30", daysDue: 30, upFrontPercent: 0, discountPercent: 1, discountDays: 10},
  { id: "Due upon receipt", daysDue: 0, upFrontPercent: 0, discountPercent: 0, discountDays: 0 },
  { id: "50% upfront, 50% on delivery", daysDue: 0, upFrontPercent: 50, discountPercent: 0, discountDays: 0 },
  { id: "100% upfront", daysDue: 0, upFrontPercent: 100, discountPercent: 0, discountDays: 0 },
];

export const LOGGING = {
  level: {
    info: "info",
    warn: "warn",
    error: "error",
    critical: "critical",
  },
  category: {
    login: "login",
    logout: "logout",
    errorBoundary: "error boundary",
  },
};

export const defaultAxiosConfig = () => {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
  };
};

export const WINDOW_OPEN_PARMS = `width=0,height=0,left=-1000,top=1`;