import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as IconAlert } from "../../assets/img/flag.svg";

const CustDropDown = ({
  id,
  label,
  onChange,
  required,
  selectedValue,
  tabIndex,
  options,
  disabled,
  showInfoIcon,
  onAdditionalInfoClick,
  valid,
  showValidationState,
}) => {
  return (
    <div className={`comp-input-wrapper${disabled ? " comp-disabled" : ""}${!valid && showValidationState ? " comp-error" : ""}`}>
      <div className="comp-input-label">
        <label htmlFor={id}>
          {label}
          {required ? " (required)" : ""}{" "}
          {showInfoIcon && (
            <IconAlert
              className="icon icon-alert ml-1 cursor-pointer"
              onMouseEnter={onAdditionalInfoClick}
            />
          )}
        </label>
      </div>
      <div className="comp-input comp-input-dropdown">
        <div className="comp-input-actions">
          <button className="comp-button comp-button-icon comp-button-border-none">
            <i className="comp-icon comp-input-dropdown-icon"></i>
          </button>
        </div>
        <div className="comp-input-value-wrapper">
          <select
            className="comp-input-value comp-input"
            id={id}
            tabIndex={tabIndex}
            onChange={onChange}
            value={selectedValue}
            disabled={disabled}
          >
            {options
              ? options
                  .filter((r) => r.key !== null)
                  .map((item) => (
                    <option key={item.key || new Date()} value={item.value}>
                      {item.text}
                    </option>
                  ))
              : null}
          </select>
        </div>
      </div>
    </div>
  );
};

CustDropDown.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.any,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  showInfoIcon: PropTypes.bool,
  onAdditionalInfoClick: PropTypes.func,
};

CustDropDown.defaultProps = {
  tabIndex: 99,
  selectedValue: "",
  disabled: false,
  showInfoIcon: false,
  onAdditionalInfoClick: () => {},
};

export default CustDropDown;
