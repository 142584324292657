import React from "react";
import PropTypes from "prop-types";

const CustCurrency = ({
  label,
  id,
  onChange,
  onBlur,
  tabIndex,
  value,
  disabled,
}) => {
  const handleFocus = (e) => {e.target.select()}
  
  return (
    <div className="comp-input-wrapper">
      <div className="comp-input-label">
        <label htmlFor={id}>{label}</label>
      </div>
      <div className="input-group" style={{ "--rounded-btn": 0 }}>
        <span className="input-group-addon">$</span>
        {!disabled && (
          <input
            id={id}
            className="comp-input no-float currency"
            type="number"
            data-number-to-fixed="2"
            onChange={onChange}
            onBlur={onBlur}
            onFocus={handleFocus}
            autoComplete="off"
            tabIndex={tabIndex}
            value={value}
            disabled={disabled}
          />
        )}
        {/*} not disabled will choke on commas.  disabled is read only, so display as regular input without numeric filtering {*/}
        {disabled && (
          <input
            id={id}
            className="comp-input no-float currency"
            autoComplete="off"
            disabled={disabled || undefined}
            onBlur={onBlur}
            onChange={onChange}
            tabIndex={tabIndex}
            type="text"
            value={value}
          />
        )}
      </div>
    </div>
  );
};

CustCurrency.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.any,
};

CustCurrency.defaultProps = {
  value: "",
  onBlur: () => {},
  onChange: () => {},
  disabled: false,
};

export default CustCurrency;
