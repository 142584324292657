import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

const CustSwitch = ({
  id,
  label,
  onChange,
  tabIndex,
  checked,
  dataTip,
  disabled,
  labelPosition,
}) => {
  let checkboxStyle = {height: "3.2rem", display: "block"},
    containerStyle={marginTop: "4px"}
  if (labelPosition === "left") {
    checkboxStyle = { marginLeft: "10px", marginTop: "-3px" };
  }

  const input =
    <input
      id={id}
      className="cust-switch"
      style={checkboxStyle}
      type="checkbox"
      checked={checked ? checked : null}
      disabled={disabled === undefined ? undefined : disabled}
      onClick={onChange}
      onChange={() => {}}
      tabIndex={tabIndex}
    />

  if (labelPosition === "top") {
    return (
      <div className="comp-switch comp-input-wrapper" style={{marginTop: "3px"}}>
        <div className="comp-input-label">
          <label htmlFor={id} data-tip={dataTip}>
            {label}
          </label>
          <div style={{marginTop: "5px"}}>
            {input}
          </div>
        </div>
        <ReactTooltip type="info" />
      </div>
    )
  }

  return (
    <div style={containerStyle}>
      <label className="comp-input-label"  htmlFor={id}>{label}</label>
      {input}
      <ReactTooltip type="info" />
    </div>
  );
};

CustSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  labelPosition: PropTypes.string,
};

CustSwitch.defaultProps = {
  checked: false,
  disabled: false,
  labelPosition: "",
};

export default CustSwitch;
