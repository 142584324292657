import axios from "axios";
import { API_SERVER, defaultAxiosConfig } from "../../constants";

export const createLogEntry = (level, message, category) => {

  axios.post(
    `${API_SERVER}logs`,
    {
      level,
      message,
      category,
      createdBy: "TODO",
    },
    { headers: defaultAxiosConfig().headers }
  );
};