import React from "react";
import Button from "devextreme-react/button";

const CustButton = React.forwardRef((props, ref) => {
  const button = 
    <Button
      id={props.id}
      className={`cust-button ${props.className || ""} ${props.custType ? `dx-button-${props.custType}` : ""}`}
      disabled={props.disabled}
      icon={props.icon}
      onClick={props.onClick}
      ref={ref}
      stylingMode={props.stylingMode || "contained"}
      tabIndex={props.tabIndex}
      text={props.text}
      type={props.type || "default"}
      visible={props.visible}
      width={props.width}
    >
      {props.text || props.children}
  </Button>

  if (props.addTopPlaceholder) {
    return (
      <div className="comp-input-wrapper">
        <div className="comp-input-label">
          <label style={{visibility: "hidden"}}>x</label>
        </div>
        {button}
      </div>
    )
  }

  return button;
});

export default CustButton;