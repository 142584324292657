function SectionTitle({ children}) {

  return (
    <div className="section-title-container section-title">
      <h3 className="section-content">{children}</h3>
    </div>

  )
}

export default SectionTitle;