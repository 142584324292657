import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";

function Home() {

  return (
    <>
      <header>
        <div className="nav-area">
          <div className="logo">
            <Link to="/">
            <img
              src={logo}
              className="pr-2"
              alt="logo"
              style={{ height: "15rem", marginLeft: "auto", marginRight: "auto", display: "block" }}
            />
            </Link>
          </div>
        </div>
      </header>
      <h1>Customer Portal</h1>

    </>
  );
}

export default Home;
