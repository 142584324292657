import add from "date-fns/add";
import isDate from "date-fns/isDate";

export const convertBlobToBase64 = async (blob) => {
  var reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      return resolve(reader.result);
    };
  });
};

export const formatFixed = (val, decimals = 2) => {
  let strVal = (val || 0).toString().replaceAll(",", "");
  return parseFloat(strVal)
    .toFixed(decimals)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isoDateToLocal = (x) => {
  if (x === null || isDate(x)) return x;

  return add(new Date(new Date(x)), {hours: 8}); 
}

// use case: downloading a .pdf onto clients local storage
export const saveFile = (url, fileName) => {
  let aTag = document.createElement('a');
  aTag.href =  url;
  aTag.style = "display: none";
  aTag.download = fileName;
  document.body.appendChild(aTag);
  aTag.click();
};

export const roundToString = (val, decimalPlaces = 2) => {
  return roundDecimal(val, decimalPlaces).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');;
};

export const roundDecimal = (val, decimalPlaces = 2) => {
  const strVal = (val || 0).toString().replaceAll(",", "");
  let num = Number(strVal);
  if (isNaN(num) || num === 0) num = 0;

  const factorOfTen = Math.pow(10, decimalPlaces);
  return (Math.round(num * factorOfTen) / factorOfTen);
};

export const formatCurrency = (val, decimalPlaces = 2) => {
  const amount = roundDecimal(val, decimalPlaces);
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);  
};
