import spinner from "../../assets/img/page-loader.gif";

function Spinner() {
  return (
    <div style={{marginTop: "17rem"}}>
      <img  className="is-loading" style={{marginLeft: "auto", marginRight: "auto", display: "block"}} src={spinner} alt="Loading..." />
    </div>
  )
}

export default Spinner