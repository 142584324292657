import React, { Children } from "react";
import { CustButton } from "./index";
// https://codepen.io/mpolizzotti/pen/boxbNO?editors=1000
// https://stackoverflow.com/questions/44327854/disable-everything-in-background-while-a-popup-is-open - basically add a div with a lower z-index than the content immediately before the drawer
export function Drawer(props) {
  const children = Children.toArray(props.children);
  const size = props.size || "small";
  const drawerWidth =
    size === "medium" ? "50%" : size === "full" ? "95%" : size === "large" ? "88%" : "25%";
    
  const headerContent = children.find(
    (x) => x.type === DrawerHeader
  ) || <p>nada</p>;
  
  const bodyContent = children.find((x) => x.type === DrawerBody) || (
    <p>nada</p>
  );


  return (
    <div className={props.showDrawer ? "modal-backdrop" : ""}>
      <div className={props.showDrawer ? "slideout-open" : ""}>
        <div
          id="drawer"
          style={{ width: drawerWidth }}
          className={`slideout`}
        >
          <div className="slideout-header p406-row-flex p406-justify-content-between p406-align-items-center">
            <div className="slideout-header__title">
              {headerContent.props.title}
            </div>
            <div>
              <CustButton
                id="slideout-close-button"
                text="Close"
                onClick={props.onClose}
              />
            </div>

          </div>
          <div className="slideout-content">
            <section className="slideout-section">
              {bodyContent.props.children}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DrawerHeader() {
  return null;
}

export function DrawerBody() {
  return null;
}

export default Drawer;